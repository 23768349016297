import styled from "styled-components";

export const Main = styled.main`
  font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  max-width: 380px;
  margin: 10px auto;

  // margin-top: 10px;
  // margin-bottom: 10px;
  // margin-right: 10px;
  // margin-left: 10px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;

  border-bottom: 1px solid #3a3a3c;

  font-weight: 700;
  font-size: 3rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  margin-bottom: 25px;
`;

export const HintSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 25px;
`;
export const Hint = styled.div`
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
`;

export const GameSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 25px;
`;
export const TileContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;

  height: 360px;
  width: 380px;
`;
export const TileRow = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr repeat(5, minmax(auto,1fr)) 1fr;
  grid-gap: 5px;
`;
export const TileEmpty = styled.div`
  display: inline-flex;
  justify-content: left;
  align-items: center;
  font-size: 0.9rem;

  user-select: none;
`;
export const TileIndicator = styled.div`
display: inline-flex;
justify-content: center;
align-items: center;

border: 2px solid transparent;
font-size: 2rem;
font-weight: bold;
line-height: 1.5rem;
text-transform: uppercase;

  user-select: none;
`;
export const Tile = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #3a3a3c;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5rem;
  text-transform: uppercase;

  ${({ hint }) => {
    if (hint === "green") {
      return `background-color: #6aaa64;`;
    }
    if (hint === "!green") {
        return `background-color: #6aaa64; border: 2px dotted #3a3a3c;`;
    }
    if (hint === "yellow") {
      return `background-color: #b59f3b;`;
    }
    if (hint === "!yellow") {
      return `background-color: #b59f3b; border: 2px dotted #3a3a3c;`;
    }
    if (hint === "grey") {
      return `background-color: #aaaaaa;`;
    }
    if (hint === "!grey") {
      return `background-color: #aaaaaa; border: 2px dotted #3a3a3c;`;
    }
  }}

  user-select: none;
`;

export const KeyboardSection = styled.section`
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const KeyboardRow = styled.div`
  width: 100%;
  margin: 0 auto 8px;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const KeyboardButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 6px 0 0;
  height: 58px;
  font-size: 0.6rem;
  ${({ item }) => (item ? `flex: ${item};` : `flex: 1;`)}

  border: 0;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;

  cursor: pointer;
  user-select: none;

  &:last-of-type {
    margin: 0;
  }

  ${({ hint }) => {
    if (hint === "green" || hint === "!green") {
      return `background-color: #6aaa64; color: #d7dadc;`;
    }
    if (hint === "yellow" || hint === "!yellow") {
      return `background-color: #b59f3b; color: #d7dadc;`;
    }
    if (hint === "grey" || hint === "!grey") {
      return `background-color: #999999; color: #d7dadc;`;
    }

    return `background-color: #cccccc; color: #333333;`;
  }}
`;

export const Flex = styled.div`
  ${({ item }) => `flex: ${item};`}
`;

export const ShareModal = styled.div`
  font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
`;

export const ShareButton = styled.button`
  font-size: 18px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid #3a3a3c;

  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: #818384;
  }
`;

export const Heading = styled.h2`
  border-bottom: 1px solid #3a3a3c;
  padding-bottom: 8px;

  font-weight: 700;
  font-size: 3.6rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 16px auto;
`;