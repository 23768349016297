import {
  Main,
  Header,
  GameSection,
  TileContainer,
  TileRow,
  Tile,
  TileEmpty,
  TileIndicator,
  KeyboardSection,
  KeyboardRow,
  Flex,
  KeyboardButton,
  HintSection,
  Hint
} from "./styled";
import { BackspaceIcon } from "./icons";
import "./App.css";
import { useEffect, useRef, useState } from "react";
import axios from 'axios'

const keyboardRows = [
  ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
  ["enter", "z", "x", "c", "v", "b", "n", "m", "backspace"],
];

const urlPrefix = ""; //"http://raspberrypi:3000";

const allKeys = keyboardRows.flat();

const wordLength = 5;

const newGame = {
  0: Array.from({ length: wordLength }).fill(""),
  1: Array.from({ length: wordLength }).fill(""),
  2: Array.from({ length: wordLength }).fill(""),
  3: Array.from({ length: wordLength }).fill(""),
  4: Array.from({ length: wordLength }).fill(""),
  5: Array.from({ length: wordLength }).fill(""),
};

function App() {
  const [guesses, setGuesses] = useState({ ...newGame });

  const [markers, setMarkers] = useState({
    0: Array.from({ length: wordLength }).fill(""),
    1: Array.from({ length: wordLength }).fill(""),
    2: Array.from({ length: wordLength }).fill(""),
    3: Array.from({ length: wordLength }).fill(""),
    4: Array.from({ length: wordLength }).fill(""),
    5: Array.from({ length: wordLength }).fill(""),
  });
  const [keyMarkers, setKeyMarkers] = useState(
    allKeys.reduce((o, key) => ({ ...o, [key]: ""}), {})
  );

  const [hint, setHint] = useState("Type first guess and click the letters to update their states.");

  const [error, setError] = useState(null);
  const [won, setWon] = useState(false);

  let letterIndex = useRef(0);
  let round = useRef(0);
  let gameId = useRef(null);

  const submit = () => {
    const _round = round.current;

    let states = "";
    for (let i of Array(wordLength).keys())
    {
      states += {"grey": " ", "yellow": "?", "green": "x", "!grey": " ", "!yellow": "?", "!green": "x"}[markers[_round][i]];
    }

    if (states === "xxxxx")
    {
      setHint("Nice job!");
      setWon(true);
      return;
    }

    let data = {game_id: gameId.current, guess: guesses[_round].join(""), states: states};
    axios.post(urlPrefix + "/api/submitguess", data)
      .then((response) => {
        if (_round < 5)
        {
        console.log(response.data);
        if (response.data.count === 1)
        {
          const updatedMarkers = {
            ...markers,
          };
          for (let i = 0; i < wordLength; i++)
          {
            publish(response.data.guesses[0][i]);
            updatedMarkers[_round+1][i] = "!green";
          }
          setMarkers(updatedMarkers);
          setHint("Nice job!");
          setWon(true);
        }
        else
        {
          setHint(`Try ${response.data.guesses.join(", ")}\n(${response.data.count} possibilities)`);
        }
        }
      })
      .catch(setError);

    round.current = _round + 1;
    letterIndex.current = 0;
  };

  const updateKeyMarkers = () => {
    let updatedKeyMarkers = allKeys.reduce((o, key) => ({ ...o, [key]: ""}), {});
    for (let wordIndex = 0; wordIndex < Object.keys(guesses).length; wordIndex++)
    {
      for (let i = 0; i < wordLength; i++)
      {
        updatedKeyMarkers[guesses[wordIndex][i]] = markers[wordIndex][i];
      }
    }
    setKeyMarkers(updatedKeyMarkers);
  }

  const erase = () => {
    const _letterIndex = letterIndex.current;
    const _round = round.current;

    if (_letterIndex !== 0) {
      const newGuesses = { ...guesses };
      newGuesses[_round][_letterIndex - 1] = "";
      setGuesses(newGuesses);

      const updatedMarkers = {
        ...markers,
      };
      updatedMarkers[_round][_letterIndex -1] = "";
      setMarkers(updatedMarkers);
      updateKeyMarkers();

      letterIndex.current = _letterIndex - 1;
    }
  };

  const publish = (pressedKey) => {
    const _letterIndex = letterIndex.current;
    const _round = round.current;

    if (_letterIndex < wordLength) {
      const newGuesses = { ...guesses };
      newGuesses[_round][_letterIndex] = pressedKey.toLowerCase();
      setGuesses(newGuesses);

      const updatedMarkers = {
        ...markers,
      };
      updatedMarkers[_round][_letterIndex] = "grey";
      for (let i = 0; i < _round; ++i)
      {
        if (guesses[i][_letterIndex] === pressedKey.toLowerCase())
        {
          let modifier = (updatedMarkers[i][_letterIndex] !== "yellow") ? "!" : "";
          updatedMarkers[_round][_letterIndex] = modifier + updatedMarkers[i][_letterIndex];
          break;
        }
      }
      setMarkers(updatedMarkers);
      updateKeyMarkers();

      letterIndex.current = _letterIndex + 1;
    }
  };

  const enterGuess = async (pressedKey) => {
    if (pressedKey === "enter" && !guesses[round.current].includes("")) {
      submit();
    } else if (pressedKey === "backspace") {
      erase();
    } else if (pressedKey !== "enter") {
      publish(pressedKey);
    }
  };

  const handleClick = (key) => {
    const pressedKey = key.toLowerCase();

    enterGuess(pressedKey);
  };

  const handleKeyDown = (e) => {
    if (won)
    {
      return;
    }

    const pressedKey = e.key.toLowerCase();

    if (allKeys.includes(pressedKey)) {
      enterGuess(pressedKey);
    }
  };

  useEffect(() => {
    if (gameId.current === null)
    {
      gameId.current = "";
      axios(urlPrefix + "/api/newgame")
      .then((response) => {
        console.log(response.data);
        gameId.current = response.data["game_id"];
      })
      .catch(setError);
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  const toggleMarker = (markers, wordIndex, i) =>
  {
    if (round.current !== wordIndex || won)
    {
      return;
    }
    if (markers[wordIndex][i][0] === "!")
    {
      return;
    }
    if (guesses[wordIndex][i][0] === "")
    {
      return;
    }

    let updatedMarkers = {
      ...markers,
    };
    updatedMarkers[wordIndex][i] = {"grey": "yellow", "yellow": "green", "green": "grey"}[updatedMarkers[wordIndex][i]]
    setMarkers(updatedMarkers);
    updateKeyMarkers();
  }
  if (error)
  {
    return (
      <>
        <Main>
          {error}
        </Main>
      </>
    );
  }
  return (
    <>
      <Main>
        <Header>WORDLEBOT</Header>
        <GameSection>
          <TileContainer>
            {Object.values(guesses).map((word, wordIndex) => (
              <TileRow key={wordIndex}>
                <TileIndicator key={wordIndex+"i"}>{round.current === wordIndex ? "⇒" : ""}</TileIndicator>
                {word.map((letter, i) => (
                  <Tile key={i} hint={markers[wordIndex][i]} onClick={() => toggleMarker(markers, wordIndex, i)}>
                    {letter}
                  </Tile>
                ))}
                <TileEmpty key={wordIndex+"s"}></TileEmpty>
              </TileRow>
            ))}
          </TileContainer>
        </GameSection>
        <HintSection>
          <Hint>{hint}</Hint>
        </HintSection>
        <KeyboardSection>
          {keyboardRows.map((keys, i) => (
            <KeyboardRow key={i}>
              {i === 1 && <Flex item={0.5} />}
              {keys.map((key) => (
                <KeyboardButton
                  key={key}
                  onClick={() => handleClick(key)}
                  flex={["enter", "backspace"].includes(key) ? 1.5 : 1}
                  hint={keyMarkers[key]}
                >
                  {key === "backspace" ? <BackspaceIcon /> : key}
                </KeyboardButton>
              ))}
              {i === 1 && <Flex item={0.5} />}
            </KeyboardRow>
          ))}
        </KeyboardSection>
      </Main>
    </>
  );
}

export default App;